export default defineNuxtRouteMiddleware(async (to, from) => {
  const maintenanceMode = false; // You can set this to true/false based on your logic
  if (maintenanceMode && to.path !== '/maintenance') {
    // Redirect user to maintenance page
    return navigateTo('/maintenance');
  }
  if (!maintenanceMode && to.path === '/maintenance') {
    return navigateTo('/');
  }
});
