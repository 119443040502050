import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const url = window.location.hostname.includes('test')
    ? `http://${window.location.hostname.replace('app.', '')}:8000`
    : `https://${window.location.hostname
        .replace('stag-f4if-app', 'stag-f4if')
        .replace('app.', '')}`;

  window.Pusher = Pusher;

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: config.public.pusherJsKey,
    wsHost: config.public.pusherJsHost,
    wsPort: 6001,
    wssPort: 443,
    disableStats: true,
    cluster: 'us2',
    forceTLS: config.public.nodeEnv !== 'development',
    authorizer: (channel) => {
      return {
        authorize: (socketId, callback) => {
          const request = $fetch(`${url}/broadcasting/auth`, {
            async onRequest({ request, options }) {
              options.method = 'post';
              options.body = {
                socket_id: socketId,
                channel_name: channel.name,
              };
              (options.headers = {
                Accept: 'application/json',
              }),
                (options.credentials = 'include');
            },
          });
          request.then((response) => {
            callback(null, response);
          });
        },
      };
    },
  });
});
