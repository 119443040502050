import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {
  faTwitter,
  faYoutube,
  faInstagram,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons';

library.add(fas, far, faTwitter, faYoutube, faInstagram, faTelegram);

config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('Icon', FontAwesomeIcon, {});
});
