import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W5VNcGB8FU from "/vercel/path0/node_modules/nuxt-3-intercom/dist/runtime/plugin.client.mjs";
import composition_sLxaNGmlSL from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import primevue_plugin_egKpok8Auk from "/vercel/path0/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/vercel/path0/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import toast_Zl2rZYKMaV from "/vercel/path0/plugins/toast.js";
import api_url_kSRCLrFzUm from "/vercel/path0/plugins/api-url.js";
import laravel_echo_tsloRA1cgn from "/vercel/path0/plugins/laravel-echo.js";
import moment_lgOtqBZZj3 from "/vercel/path0/plugins/moment.js";
import fontawesome_klhsrycjcK from "/vercel/path0/plugins/fontawesome.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W5VNcGB8FU,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  toast_Zl2rZYKMaV,
  api_url_kSRCLrFzUm,
  laravel_echo_tsloRA1cgn,
  moment_lgOtqBZZj3,
  fontawesome_klhsrycjcK
]