export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const url = window.location.hostname.includes('test')
      ? `http://${window.location.hostname.replace('app.', '')}:8000`
      : `https://${window.location.hostname
          .replace('stag-f4if-app', 'stag-f4if')
          .replace('app.', '')}`;

    const path = window.location.hostname.includes('test')
      ? `http://${window.location.hostname.replace('app.', '')}:8000`
      : `https://${window.location.hostname
          .replace('stag-f4if-app', 'stag-f4if')
          .replace('app.', '')}`;

    nuxtApp.provide('apiUrl', url);
    nuxtApp.provide('path', path);
  }
});
