import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/vercel/path0/middleware/maintenance.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/vercel/path0/middleware/admin.js"),
  auth: () => import("/vercel/path0/middleware/auth.js"),
  guest: () => import("/vercel/path0/middleware/guest.js"),
  whitelisted: () => import("/vercel/path0/middleware/whitelisted.js")
}