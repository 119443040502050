import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoCoinfaucet, LazySvgoFlokigames, LazySvgoFreebfg, LazySvgoFreebinancecoin, LazySvgoFreebitcoin, LazySvgoFreebittorrent, LazySvgoFreecardano, LazySvgoFreechain, LazySvgoFreedash, LazySvgoFreedoge, LazySvgoFreeethereum, LazySvgoFreelitecoin, LazySvgoFreematic, LazySvgoFreenem, LazySvgoFreeneo, LazySvgoFreepancake, LazySvgoFreeshibainu, LazySvgoFreesteam, LazySvgoFreetether, LazySvgoFreetron, LazySvgoFreeusdcoin, LazyDropdown, LazyInputGroup, LazyInputNumber, LazyInputText, LazyPassword, LazyButton, LazyColumn, LazyDataTable, LazyCard, LazyFieldset, LazyPanel, LazyTabView, LazyTabPanel, LazyDialog, LazySidebar, LazyMenu, LazyMessage, LazyToast, LazyCarousel, LazySkeleton, LazyProgressBar, LazyProgressSpinner } from '#components'
const lazyGlobalComponents = [
  ["SvgoCoinfaucet", LazySvgoCoinfaucet],
["SvgoFlokigames", LazySvgoFlokigames],
["SvgoFreebfg", LazySvgoFreebfg],
["SvgoFreebinancecoin", LazySvgoFreebinancecoin],
["SvgoFreebitcoin", LazySvgoFreebitcoin],
["SvgoFreebittorrent", LazySvgoFreebittorrent],
["SvgoFreecardano", LazySvgoFreecardano],
["SvgoFreechain", LazySvgoFreechain],
["SvgoFreedash", LazySvgoFreedash],
["SvgoFreedoge", LazySvgoFreedoge],
["SvgoFreeethereum", LazySvgoFreeethereum],
["SvgoFreelitecoin", LazySvgoFreelitecoin],
["SvgoFreematic", LazySvgoFreematic],
["SvgoFreenem", LazySvgoFreenem],
["SvgoFreeneo", LazySvgoFreeneo],
["SvgoFreepancake", LazySvgoFreepancake],
["SvgoFreeshibainu", LazySvgoFreeshibainu],
["SvgoFreesteam", LazySvgoFreesteam],
["SvgoFreetether", LazySvgoFreetether],
["SvgoFreetron", LazySvgoFreetron],
["SvgoFreeusdcoin", LazySvgoFreeusdcoin],
["Dropdown", LazyDropdown],
["InputGroup", LazyInputGroup],
["InputNumber", LazyInputNumber],
["InputText", LazyInputText],
["Password", LazyPassword],
["Button", LazyButton],
["Column", LazyColumn],
["DataTable", LazyDataTable],
["Card", LazyCard],
["Fieldset", LazyFieldset],
["Panel", LazyPanel],
["TabView", LazyTabView],
["TabPanel", LazyTabPanel],
["Dialog", LazyDialog],
["Sidebar", LazySidebar],
["Menu", LazyMenu],
["Message", LazyMessage],
["Toast", LazyToast],
["Carousel", LazyCarousel],
["Skeleton", LazySkeleton],
["ProgressBar", LazyProgressBar],
["ProgressSpinner", LazyProgressSpinner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
