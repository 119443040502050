import moment from 'moment';
import 'moment/dist/locale/es.js';
import 'moment/dist/locale/ar.js';
import 'moment/dist/locale/da.js';
import 'moment/dist/locale/de.js';
import 'moment/dist/locale/fr.js';
import 'moment/dist/locale/id.js';
import 'moment/dist/locale/ja.js';
import 'moment/dist/locale/ko.js';
import 'moment/dist/locale/pl.js';
import 'moment/dist/locale/pt.js';
import 'moment/dist/locale/ru.js';
import 'moment/dist/locale/th.js';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('moment', moment);
});
